import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

const NavListLink = function (props: Record<string, any>) {
  return (
    <ListItem button component='a' href={props.target} key={props.target}>
      <ListItemIcon color='inherit' style={{}}>
        {props.children}
      </ListItemIcon>
      <ListItemText
        primary={props.text}
        primaryTypographyProps={{
          variant: 'button',
        }}
      />
    </ListItem>
  );
};

export default NavListLink;
