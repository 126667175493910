import {
  AUTH_ACCESS_TOKEN_SELECTOR,
  AUTH_USER_SELECTOR,
} from '@/Features/Auth/auth.slice';
import { AppBar, Box, Toolbar, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TopNav } from '../Navigation/TopNav';
import { H6 } from '../Typography';

export const TopAppBar = (props: {
  isSignedIn: boolean;
  handleMenuClick: (sidebarOpen: boolean) => void;
}) => {
  const theme = useTheme();

  return (
    <Box>
      <AppBar position='relative' sx={{ boxShadow: 'none' }}>
        <Toolbar sx={{ width: '100%' }}>
          {/* <IconButton
            size='large'
            edge='start'
            color='inherit'
            sx={{ mr: 2 }}
            onClick={() => props.handleMenuClick(true)}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <BreadcrumbRouter /> */}
          <Link
            to='/'
            style={{
              color: theme.palette.primary.contrastText,
              cursor: 'pointer',
              fontFamily: theme.typography.button.fontFamily,
              fontSize: theme.typography.h4.fontSize,
              textDecoration: 'none',
              textTransform: 'uppercase',
            }}
          >
            Kip. Providers
          </Link>
          {!!props.isSignedIn && <TopNav />}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
