import { User } from '@kip/common';

const storagePrefix = 'kip_providers_';

const storage = {
  token: {
    get: () => {
      const token = window.sessionStorage.getItem(`${storagePrefix}token`);
      if (token) {
        return JSON.parse(token);
      }
      return null;
    },
    set: (token: string) => {
      window.sessionStorage.setItem(
        `${storagePrefix}token`,
        JSON.stringify(token)
      );
    },
    clear: () => {
      window.sessionStorage.removeItem(`${storagePrefix}token`);
    },
  },
  refreshToken: {
    get: () => {
      const refreshToken = window.sessionStorage.getItem(
        `${storagePrefix}refreshToken`
      );
      if (refreshToken) {
        return JSON.parse(refreshToken);
      }
      return null;
    },
    set: (refreshToken: string) => {
      window.sessionStorage.setItem(
        `${storagePrefix}refreshToken`,
        JSON.stringify(refreshToken)
      );
    },
    clear: () => {
      window.sessionStorage.removeItem(`${storagePrefix}refreshToken`);
    },
  },
  user: {
    get: () => {
      const user = window.sessionStorage.getItem(`${storagePrefix}user`);
      if (user) {
        return JSON.parse(user);
      }
      return null;
    },
    set: (user: User) => {
      window.sessionStorage.setItem(
        `${storagePrefix}user`,
        JSON.stringify(user)
      );
    },
    clear: () => {
      window.sessionStorage.removeItem(`${storagePrefix}user`);
    },
  },
};

export default storage;
