import createTheme from '@mui/material/styles/createTheme';
import components from './components';
import palette from './palette';
import typography from './typography';

export const kipTheme = createTheme({
  components: components,
  palette: palette,
  typography: typography,
  shape: {
    borderRadius: 0,
  },
});

export default kipTheme;
