import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ErrorFallback } from '@/ErrorFallback';
import { NotificationProvider } from '@/Components/Notifications/NotificationProvider';
// import { SignIn } from '@/Features/Auth';
// import { Dashboard } from '@/Features/Dashboard/Dashboard';
// import { ClientView, ClientsListView } from '@/Features/Clients';
// import { SignOut } from './Features/Auth/SignOut';
import Layout from './Components/Layout';
import { ThemeProvider } from '@mui/material';
import { kipTheme } from '@/Theme';
import storage from './Utils/storage';
import RequiresRole from './Components/Navigation/RequiresRole';

const ClientsListView = React.lazy(
  () => import('@/Features/Clients/ClientsListView')
);
const ReportsView = React.lazy(() => import('@/Features/Reports/views/ReportsView'))
const ProvidersView = React.lazy(() => import('@/Features/Providers/views/ProvidersView'));
const ClientView = React.lazy(() => import('@/Features/Clients/ClientView'));
const Dashboard = React.lazy(() => import('@/Features/Dashboard/Dashboard'));
const SignIn = React.lazy(() => import('@/Features/Auth/SignIn'));
const SignOut = React.lazy(() => import('@/Features/Auth/SignOut'));

const App = () => {
  const [accessToken, setAccessToken] = useState(storage.token.get());
  const [refreshToken, setRefreshToken] = useState(storage.refreshToken.get());
  const [isSignedIn, setIsSignedIn] = useState(!!accessToken);
  const [isThereANewClient, setIsThereANewClient] = useState(false);

  const renderLoader = () => <div>Loading.</div>;

  useEffect(() => {
    setIsSignedIn(!!accessToken);
  }, [accessToken]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={kipTheme}>
        <Layout isSignedIn={isSignedIn}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <React.Suspense fallback={renderLoader()}>
              <NotificationProvider />
              <Routes>
                {!!accessToken ? (
                  <>
                    <Route
                      path='/'
                      element={
                        <Dashboard
                          setIsThereANewClient={setIsThereANewClient}
                        />
                      }
                    />
                    <Route
                      path='clients'
                      element={
                        <ClientsListView
                          isThereANewClient={isThereANewClient}
                          setIsThereANewClient={setIsThereANewClient}
                        />
                      }
                    />
                    <Route
                      path='providers'
                      element={
                        <RequiresRole match='ADMIN' routeAttempt='Providers'>
                          <ProvidersView />
                        </RequiresRole>
                      }
                    />
                    <Route
                      path='reports'
                      element={
                        <RequiresRole match='ADMIN' routeAttempt='Reports'>
                          <ReportsView />
                        </RequiresRole>
                      }
                    />
                    <Route path='clients/:clientId' element={<ClientView />} />
                    <Route
                      path='signout'
                      element={
                        <SignOut
                          success={() => setAccessToken(storage.token.get())}
                        />
                      }
                    />
                    <Route path='*' element={<Navigate replace to='/' />} />
                  </>
                ) : (
                  <>
                    <Route
                      path='/'
                      element={<Navigate replace to='/signin' />}
                    />
                    <Route
                      path='/signin'
                      element={
                        <SignIn
                          success={(
                            accessToken: string,
                            refreshToken: string
                          ) => {
                            setAccessToken(accessToken);
                            setRefreshToken(refreshToken);
                          }}
                        />
                      }
                    />
                  </>
                )}
                <Route path='*' element={<Navigate replace to='/' />} />
              </Routes>
            </React.Suspense>
          </ErrorBoundary>
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
