import { PaletteOptions } from '@mui/material/styles/createPalette';

const grey = {
  50: 'rgba(252, 252, 253, 1)',
  100: 'rgba(230, 231, 234, 1)',
  200: 'rgba(215, 219, 229, 1)',
  300: 'rgba(204, 210, 222, 1)',
  400: 'rgba(193, 200, 215, 1)',
  500: 'rgba(170, 180, 200, 1)',
  600: 'rgba(146, 159, 185, 1)',
  700: 'rgba(97, 108, 130, 1)',
  800: 'rgba(48, 57, 75, 1)',
  900: 'rgba(12, 14, 19, 1)',
};

const backgroundDefault = 'rgba(255, 250, 240, 1)';
// const backgroundDefault = 'rgba(255, 230, 223, 1)';

const palette: PaletteOptions = {
  common: {
    black: grey[900],
    white: grey[50],
  },
  background: {
    paper: grey[50],
    default: backgroundDefault,
  },
  primary: {
    light: 'rgba(255, 134, 105, 1)',
    main: 'rgba(201, 68, 38, 1)',
    dark: 'rgba(145, 5, 0, 1)',
    contrastText: backgroundDefault,
  },
  secondary: {
    light: 'rgba(255, 221, 89, 1)',
    main: 'rgba(242, 171, 34, 1)',
    dark: 'rgba(186, 124, 0, 1)',
    contrastText: grey[900],
  },
  error: {
    light: 'rgba(255, 51, 102, 1)',
    main: 'rgba(255, 0, 0, 1)',
    dark: 'rgba(204, 0, 0, 1',
    contrastText: grey[900],
  },
  info: {
    main: 'rgba(193, 200, 215, 1)',
    light: 'rgba(215, 219, 229, 1)',
    dark: 'rgba(146, 159, 185, 1)',
    contrastText: grey[900],
  },
  success: {
    light: 'rgba(102, 255, 153, 1)',
    main: 'rgba(51, 204, 102, 1)',
    dark: 'rgba(0, 102, 51, 1)',
    contrastText: grey[900],
  },
  text: {
    primary: grey[900],
    secondary: grey[600],
    disabled: grey[800],
  },
  grey: grey,
};

export default palette;
