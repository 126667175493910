import { TypographyOptions } from '@mui/material/styles/createTypography';

const LIGHT = 200;
const REGULAR = 400;
const BOLD = 600;

const FontFamily = {
  COPY: ['Roboto', 'Open Sans', 'Helvetica Neue', 'sans-serif'].join(','),
  HEADING: ['Jost', 'Verdana', 'Arial', 'sans-serif'].join(','),
};

const typography: TypographyOptions = {
  fontFamily: FontFamily.COPY,
  fontSize: 12,
  fontWeightBold: BOLD,
  fontWeightRegular: REGULAR,
  fontWeightLight: LIGHT,
  h1: {
    fontFamily: FontFamily.HEADING,
    fontWeight: LIGHT,
    fontSize: '2.89rem',
    lineHeight: '3.56rem',
    font: `${LIGHT} 2.89rem Jost 3.56rem`,
  },
  h2: {
    fontFamily: FontFamily.HEADING,
    fontWeight: LIGHT,
    fontSize: '2.44rem',
    lineHeight: '3rem',
  },
  h3: {
    fontFamily: FontFamily.HEADING,
    fontWeight: LIGHT,
    fontSize: '1.78rem',
    lineHeight: '2.22rem',
  },
  h4: {
    fontFamily: FontFamily.HEADING,
    fontWeight: BOLD,
    fontSize: '1.44rem',
    lineHeight: '1.78rem',
  },
  h5: {
    fontFamily: FontFamily.HEADING,
    fontWeight: BOLD,
    fontSize: '1.11rem',
    lineHeight: '1.44rem',
  },
  h6: {
    fontFamily: FontFamily.HEADING,
    fontWeight: BOLD,
    fontSize: '1rem',
    lineHeight: '1.35rem',
  },
  subtitle1: {
    fontFamily: FontFamily.HEADING,
    fontWeight: LIGHT,
    fontSize: '26px',
    lineHeight: '32px',
    fontVariantCaps: 'all-small-caps',
  },
  button: {
    fontFamily: FontFamily.HEADING,
    fontWeight: REGULAR,
    fontSize: '16px',
    lineHeight: '24px',
  },
};

export default typography;
