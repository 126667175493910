import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer } from '@/Features/Auth/auth.slice';
import { clientsReducer } from '@/Features/Clients/clients.slice';
import { globalReducer } from './global.slice';

export const rootReducer = combineReducers({
  auth: authReducer,
  clients: clientsReducer,
  global: globalReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
