import React, { useState } from 'react';
import { Head } from './Head';
import { Footer } from './Footer';
import { TopAppBar } from './TopAppBar';
import { Sidebar } from './Sidebar';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';

type LayoutProps = {
  children?: React.ReactNode;
  title?: string;
  isSignedIn?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    backgroundColor: theme.palette.background.default,
  },
  content: {
    paddingTop: '1rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80vw',
    maxWidth: theme.breakpoints.values.lg,
  },
  wrap: {
    minHeight: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
  },
  footer: {
    textAlign: 'right',
    paddingRight: '1rem',
  },
}));

export const Layout = (props: LayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.root}>
      <Head title={props.title || ''} />
      <TopAppBar
        isSignedIn={!!props.isSignedIn}
        handleMenuClick={() => setSidebarOpen(!sidebarOpen)}
      />
      <Sidebar
        sidebarOpen={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      />
      <div className={classes.content}>{props.children}</div>
      <Footer className={classes.footer} />
    </div>
  );
};

export default Layout;
