import { Components } from '@mui/material/styles/components';

const components: Components = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      variant: 'contained',
    },
    styleOverrides: {
      disabled: 'disabled',
    },
  },
  MuiCard: {
    defaultProps: {
      elevation: 2,
    },
  },
};

export default components;
