import { ToastBar, Toaster } from 'react-hot-toast';
import { keyframes } from 'goober';
import { useTheme } from '@mui/material';

export var NotificationProvider = function () {
  const theme = useTheme();

  return (
    <Toaster
      position='bottom-right'
      toastOptions={{
        style: {
          color: theme.palette.info.contrastText,
          backgroundColor: theme.palette.info.main,
          borderRadius: '0.22rem',
          boxShadow: 'none',
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.h5.fontSize,
          fontWeight: theme.typography.fontWeightRegular,
          lineHeight: theme.typography.body1.lineHeight,
          padding: '0.5rem',
        },
        error: {
          style: {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
          },
        },
        success: {
          style: {
            color: theme.palette.success.contrastText,
            backgroundColor: theme.palette.success.light,
          },
        },
      }}
    >
      {(t) => (
        <ToastBar
          toast={t}
          style={{
            ...t.style,
            animation: t.visible
              ? `${keyframes('0%{opacity:0;} 100%{opacity:1;}')} 1s ease`
              : `${keyframes('100%{opacity:0;} 0%{opacity:1;}')} 1s ease`,
          }}
        />
      )}
    </Toaster>
  );
};
