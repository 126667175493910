import { useTheme } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';

import React from 'react';

const Label = function (props: {
  children: React.ReactChild | string;
  typographyProps?: Partial<TypographyProps<'div'>>;
}) {
  const theme = useTheme();
  return (
    <Typography
      variant='body1'
      fontFamily={theme.typography.h1.fontFamily}
      fontSize={theme.typography.h5.fontSize}
      fontWeight={theme.typography.fontWeightBold}
      style={{ fontVariantCaps: 'all-small-caps' }}
      {...props.typographyProps}
    >
      {props.children}
    </Typography>
  );
};

export default Label;
