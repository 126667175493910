import { Home, Logout, PeopleAlt } from '@mui/icons-material';
import { Drawer, List } from '@mui/material';
import NavListLink from '@/Components/Navigation/NavListLink';

export const Sidebar = (props: {
  sidebarOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Drawer
      className='SideDrawer'
      variant='temporary'
      open={props.sidebarOpen}
      onClose={props.onClose}
    >
      <div className='DrawerContainer'>
        <img
          src='/logo192.png'
          width='100%'
          height='auto'
          alt='Kip. Providers'
        />
        <List>
          <NavListLink target='/' text='Home'>
            <Home />
          </NavListLink>
          <NavListLink target='/clients' text='Clients'>
            <PeopleAlt />
          </NavListLink>
          {/* <NavListLink target='/schedule' text='Schedule'>
            <TodaySharp />
          </NavListLink>
          <NavListLink target='/profile' text='Profile'>
            <PersonSharp />
          </NavListLink> */}
          <NavListLink target='/signout' text='Sign Out'>
            <Logout />
          </NavListLink>
        </List>
      </div>
    </Drawer>
  );
};
