import storage from "@/Utils/storage";
import { User } from "@kip/common";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { H5 } from "../Typography";

interface RequiresRoleProps {
  match: string
  routeAttempt?: string
}

const RequiresRole: React.FC<RequiresRoleProps> = ({
  match,
  routeAttempt,
  children
}): JSX.Element => {
  const [user, setUser] = useState<User>(storage.user.get());
  return (
    <>
    {user.role === match ? (
      children
    ) : (
      <Box textAlign="center" marginTop="50px">
        <H5>
          You don't have the permissions to access {(routeAttempt || 'this route')}.
        </H5>
      </Box>
    )}
    </>
  )
}

export default RequiresRole