import { Helmet } from 'react-helmet-async';

type HeadProps = {
  title?: string;
  description?: string;
};

export const Head = ({ title, description }: HeadProps = {}) => {
  return (
    <Helmet
      title={title ? `${title} | Kip Therapy` : 'Kip Therapy'}
      defaultTitle='Kip Therapy Electronic Health Records'
    >
      <meta name='description' content={description} />
    </Helmet>
  );
};

export default Head;
