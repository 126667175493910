import React from 'react';
import Button from '@mui/material/Button';
import { H1, P } from '@/Components/Typography/Typography';
import { Grid, Typography } from '@mui/material';

export const ErrorFallback = ({ error }: { error: any }) => {
  return (
    <div className='ErrorFallback' role='alert'>
      <Grid container spacing={4}>
        <Grid item xs={'auto'}>
          {!!error?.statusCode && (
            <Typography variant='h1' fontWeight={'bold'}>
              {error.statusCode}
            </Typography>
          )}
        </Grid>
        <Grid item xs={'auto'}>
          <H1>{`${error?.error ?? 'Something unexpected happened.'}`}</H1>
        </Grid>
      </Grid>
      <P>{error?.message ?? "That's all we know."}</P>
      <Button onClick={() => window.location.reload()} sx={{ mt: 4 }}>
        Start Over
      </Button>
    </div>
  );
};

export default ErrorFallback;
