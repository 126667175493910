import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  SxProps,
  useTheme,
} from '@mui/material';
import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

const NavComponent = (
  props: Omit<NavLinkProps, 'children'> & {
    icon: ReactElement;
    style?: CSSProperties;
    sx?: SxProps;
    text: string;
  }
) => {
  let icon: React.ReactElement = props.icon;
  const children = (
    <>
      {props.icon}
      {props.text}
    </>
  );
  const theme = useTheme();
  return (
    <Box sx={props.sx} style={props.style}>
      <NavLink
        to={props.to}
        className={(params: { isActive: boolean }) =>
          'navigationLink' + (params.isActive ? ' selected' : '')
        }
        style={(params: { isActive: boolean }) => ({
          color: params.isActive
            ? theme.palette.secondary.main
            : theme.palette.primary.contrastText,
          fontFamily: theme.typography.h1.fontFamily,
          textTransform: 'uppercase',
          textDecoration: 'none',
          display: 'inline-flex',
          alignContent: 'baseline',
          paddingLeft: '1rem',
          paddingRight: '1rem',
        })}
      >
        {children}
      </NavLink>
    </Box>
  );
};

export const NavigationLink = styled(NavComponent)(({ theme }) => ({
  '& .navigationLink': {
    color: theme.palette.primary.contrastText,
  },
  '& .navigationLink .selected': {
    color: theme.palette.secondary.main,
  },
}));
