import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '@kip/common';
import { RootState } from '@/Store/store';

type ClientDto = Partial<Client>;

export type ClientsSliceState = {
  clientShortList?: ClientDto[];
  currentClient?: ClientDto;
};

const initialState: ClientsSliceState = {
  clientShortList: [],
  currentClient: {},
};

export const {
  actions: {
    setClientShortList,
    clearClientShortList,
    setCurrentClient,
    clearCurrentClient,
  },
  reducer: clientsReducer,
} = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClientShortList: (state, action: PayloadAction<ClientDto[]>) => {
      state.clientShortList = action.payload;
    },
    clearClientShortList: (state) => {
      state.clientShortList = [];
    },
    setCurrentClient: (state, action: PayloadAction<ClientDto>) => {
      state.currentClient = action.payload;
    },
    clearCurrentClient: (state) => {
      state.currentClient = {};
    },
  },
});

export const CLIENTS_SHORT_LIST_SELECTOR = (state: RootState) =>
  state.clients.clientShortList;
