import Typography from '@mui/material/Typography';
import React from 'react';

export const H1 = (props: { children: React.ReactNode }) => {
  return <Typography variant='h1'>{props.children}</Typography>;
};
export const H2 = (props: { children: React.ReactNode }) => {
  return <Typography variant='h2'>{props.children}</Typography>;
};
export const H3 = (props: { children: React.ReactNode }) => {
  return <Typography variant='h3'>{props.children}</Typography>;
};
export const H4 = (props: { children: React.ReactNode }) => {
  return <Typography variant='h4'>{props.children}</Typography>;
};
export const H5 = (props: { children: React.ReactNode }) => {
  return <Typography variant='h5'>{props.children}</Typography>;
};
export const H6 = (props: { children: React.ReactNode }) => {
  return <Typography variant='h6'>{props.children}</Typography>;
};
export const TITLE = (props: { children: React.ReactNode }) => {
  return <Typography variant='h1'>{props.children}</Typography>;
};
export const SUBTITLE = (props: { children: React.ReactNode }) => {
  return (
    <Typography variant='subtitle1' component='h2'>
      {props.children}
    </Typography>
  );
};
export const P = (props: { children: React.ReactNode }) => {
  return (
    <Typography variant='body1' component='p'>
      {props.children}
    </Typography>
  );
};
