import React from 'react';
import Typography from '@mui/material/Typography';

export const Footer = (props: { className?: string }) => {
  return (
    <div className={['footer', props.className].join(' ')}>
      <Typography variant='body2' color='textSecondary'>
        © {new Date().getFullYear()} by Kip Therapy. All rights reserved.
      </Typography>
    </div>
  );
};
