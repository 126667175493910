import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export type ActiveProvider = {
  id: string;
  name: string;
};

export type GlobalSliceState = {
  activeProvidersList: ActiveProvider[];
  breadcrumbs: { label: string; href: string }[];
};

const initialState: GlobalSliceState = {
  activeProvidersList: [],
  breadcrumbs: [{ label: 'Home', href: '/' }],
};

export const {
  actions: { setActiveProvidersList, clearActiveProvidersList },
  reducer: globalReducer,
} = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setActiveProvidersList: (
      state,
      action: PayloadAction<ActiveProvider[]>
    ) => {
      state.activeProvidersList = action.payload;
    },
    clearActiveProvidersList: (state) => {
      state.activeProvidersList = [];
    },
    setBreadcrumbs: (
      state,
      action: PayloadAction<{ label: string; href: string }[]>
    ) => {
      state.breadcrumbs = action.payload;
    },
    addBreadcrumb: (
      state,
      action: PayloadAction<{ label: string; href: string }>
    ) => {
      state.breadcrumbs.push(action.payload);
    },
    removeBreadcrumb: (state) => {
      state.breadcrumbs.pop();
    },
    resetBreadcrumbs: (state) => {
      state.breadcrumbs = initialState.breadcrumbs;
    },
  },
});

export const GLOBAL_ACTIVE_PROVIDERS_LIST = (state: RootState) =>
  state.global.activeProvidersList;

export const GET_BREADCRUMBS = (state: RootState) => state.global.breadcrumbs;
